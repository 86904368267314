<template>
  <div style="padding: 32px 0px 16px">
    <v-overlay :value="overlay" absolute dark></v-overlay>
    <v-card flat>
      <v-card-text>
        <v-tabs v-model="myHistory">
          <v-tab key="complaints">complaints</v-tab>
          <v-tab key="systemic">systemic enquiry</v-tab>
          <v-tab key="notes">Notes</v-tab>
          <v-tab key="summary_history">summary of history</v-tab>
        </v-tabs>
        <v-tabs-items v-model="myHistory">
          <v-tab-item key="complaints" style="padding-top: 20px">
            <v-card v-resize="onResize" flat>
              <div :style="{ height: panheight }" class="scrollable">
                <v-card-text>
                  <v-overlay :value="overlay" absolute dark></v-overlay>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-textarea
                          v-model="create.main_problem"
                          counter
                          label="Presenting Complaints"
                          maxlength="250"
                          outlined
                          rows="5"
                      ></v-textarea>
                      <v-textarea
                          v-model="create.historic_complaint"
                          counter
                          label="History of presenting complaints"
                          maxlength="250"
                          outlined
                          rows="5"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-switch
                          v-model="create.drugs"
                          :label="`Are you on any medications: ${create.drugs}`"
                          style="margin-top: 1px; padding-left: 4px"
                      ></v-switch>

                      <v-textarea
                          v-model="create.drug_details"
                          :disabled="create.drugs ? false : true"
                          counter
                          label="medication details"
                          maxlength="500"
                          outlined
                          rows="9"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item key="systemic" style="padding-top: 20px">
            <v-card
                v-resize="onResize"
                class="dyna_form_data"
                flat
                style="padding-bottom: 12px"
            >
              <div :style="{ height: panheight }" class="scrollable">
                <v-card-text>
                  <v-row>
                    <v-col
                        v-for="n in dataList"
                        :key="n.id"
                        class="pa-2"
                        outlined
                        tile
                    >
                      <v-card outlined style="min-width: 280px">
                        <v-toolbar
                            color="rgb(245 245 245)"
                            dense
                            flat
                            style="height: 35px"
                        >
                          <v-toolbar-title style="height: 35px">
                            {{ n.name }}
                          </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                          <ul v-for="u in n.details" :key="u.id">
                            <li>

                              <v-checkbox v-model="u.selected"
                                          :label="`${u.name} ${u.selected}`"
                                          :value="u.selected"
                                          dense hide-details @change="changeVal(u)"
                              ></v-checkbox>


                              <!--                              <v-checkbox-->
                              <!--                                  v-model="create.selectedDetail"-->
                              <!--                                  :label="`${u.name} ${u.selected}`"-->
                              <!--                                  :style="{backgroundColor: u.selected ? '#f5ffb7':'transparent'}"-->
                              <!--                                  :value="u"-->
                              <!--                                  dense hide-details-->
                              <!--                              ></v-checkbox>-->
                            </li>
                          </ul>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item key="notes" style="padding-top: 20px">
            <v-card v-resize="onResize">
              <div :style="{ height: panheight }" class="scrollable">
                <v-card-text>
                  <vue-editor v-model="create.my_notes"></vue-editor>
                </v-card-text>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item key="summary_history" style="padding-top: 20px">
            <v-card v-resize="onResize">
              <div :style="{ height: panheight }" class="scrollable">
                <v-card-text>
                  <vue-editor v-model="create.summary_history"></vue-editor>
                </v-card-text>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-btn
            :disabled="$v.create.$invalid"
            color="primary"
            text
            @click="submit"
        >
          save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import Utils from "@/mixins/utils";
import Measurements from "../../../../mixins/measurements";
import {VueEditor} from "vue2-editor";

export default {
  props: {id: {type: String}},
  mixins: [Utils, Measurements, validationMixin],
  components: {
    VueEditor,
  },
  data: () => ({
    overlay: false,
    myHistory: null,
    dataList: [],
    create: {
      myId: null,
      main_problem: null,
      drugs: false,
      drug_details: null,
      historic_complaint: null,
      selectedDetail: [],
      my_notes: null,
      summary_history: null,
    },
  }),
  validations: {
    create: {
      main_problem: {
        required,
      },
      drugs: {
        required,
      },
      // drug_details: {
      //   required,
      // },
    },
  },
  watch: {
    "create.selectedDetail": function (a) {
      console.log("a: ", a);
      // console.log("a: ", JSON.stringify(a));
    },
  },
  computed: {
    panheight: function () {
      let h = this.windowSize.y - 425 + "px";
      return h;
    },
  },
  created() {
    this.find();
    this.list();
    // this.accepted();
  },
  methods: {
    find() {
      this.overlay = true;
      console.log(this._decode(this.id));
      Restful.medics.treatment.history
          .list(this._decode(this.id))
          .then((response) => {
            console.log(response.data);
            if (response.status === 200) {
              this.create.myId = response.data.id;
              this.create.main_problem = response.data.main_problem;
              this.create.historic_complaint = response.data.historic_complaint;
              this.create.drugs = response.data.drugs;
              this.create.drug_details = response.data.drug_details;
              this.create.my_notes = response.data.medic_notes;
              this.create.summary_history = response.data.summary_history;
            }
            this.overlay = false;
          })
          .catch((error) => {
            console.log(error.response);
          });
    },
    list() {
      this.overlay = true;
      this.loading = true;
      let self = this;
      console.log(this._decode(this.id));
      Restful.medics.treatment.enquiry
          .forEdits(this._decode(this.id))
          .then((response) => {
            console.log(response.data);
            if (response.status === 200) {
              this.dataList = response.data;
            }
            this.loading = false;
            this.overlay = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
            self.loading = false;
            self.overlay = false;
            self.isPresent = false;
            self.isDisabled = true;
          });
    },
    changeVal(a) {
      this.create.selectedDetail.push(a);
      console.log('selectedDetail:', JSON.stringify(this.create.selectedDetail))
    },
    // accepted() {
    //   this.overlay = true;
    //   let self = this;
    //   console.log(this._decode(this.id));
    //   Restful.medics.treatment.enquiry
    //       .accepted(this._decode(this.id))
    //       .then((response) => {
    //         console.log('ass', response.data);
    //         if (response.status === 200) {
    //           this.create.selectedDetail = response.data;
    //         }
    //         this.overlay = false;
    //       })
    //       .catch((error) => {
    //         console.log(error.response);
    //         self.snack.color = "error";
    //         self.snack.text = error.response.data;
    //       });
    // },
    submit() {
      let medics_available_timesid = parseInt(this._decode(this.id));
      let main_problem = this.create.main_problem;
      let drugs = this.create.drugs;
      let drug_details = this.create.drug_details;
      let historic_complaint = this.create.historic_complaint;
      let jsonData = this.create.selectedDetail;
      let medic_notes = this.create.my_notes;
      let summary_history = this.create.summary_history;

      let data = {
        medics_available_timesid,
        main_problem,
        drugs,
        drug_details,
        historic_complaint,
        medic_notes,
        jsonData,
        summary_history,
      };
      let id = this.create.myId;
      let payload = {id: id, data: data};
      console.log('dataList:', this.dataList);
      console.log("payload", JSON.stringify(payload));
      this.$emit("submitEdit", payload);
    },
  },
};
</script>

<template>
  <div style="padding: 7px 0px 16px">
    <v-tabs v-model="myHistory">
      <v-tab key="complaints">complaints</v-tab>
      <v-tab key="systemic">systemic enquiry</v-tab>
      <v-tab key="notes">Notes</v-tab>
      <v-tab key="summary_history">summary of history</v-tab>
    </v-tabs>
    <v-tabs-items v-model="myHistory">
      <v-tab-item key="complaints" style="padding-top: 20px">
        <v-card v-resize="onResize" flat>
          <div :style="{ height: panheight }" class="scrollable">
            <v-card-text>
              <v-overlay :value="overlay" absolute dark></v-overlay>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-textarea
                      v-model="create.main_problem"
                      counter
                      label="Presenting Complaints  *"
                      maxlength="250"
                      outlined
                      rows="5"
                  ></v-textarea>
                  <v-textarea
                      v-model="create.historic_complaint"
                      counter
                      label="History of presenting complaints"
                      maxlength="250"
                      outlined
                      rows="5"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-switch
                      v-model="create.drugs"
                      :label="`Are you on any medications: ${create.drugs}`"
                      style="margin-top: 1px; padding-left: 4px"
                  ></v-switch>

                  <v-textarea
                      v-model="create.drug_details"
                      :disabled="create.drugs ? false : true"
                      counter
                      label="medication history"
                      maxlength="500"
                      outlined
                      rows="9"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item key="systemic" v-resize="onResize" style="padding-top: 20px">
        <div :style="{ height: panheight }" class="scrollable">
          <v-card class="dyna_form_data" flat style="padding-bottom: 12px">
            <v-card-text>
              <div class="mandatory">
                Select at least one item
              </div>
              <v-row>
                <v-col
                    v-for="n in dataList"
                    :key="n.id"
                    class="pa-2"
                    outlined
                    tile
                >
                  <v-card outlined style="min-width: 280px">
                    <v-toolbar
                        color="rgb(245 245 245)"
                        dense
                        flat
                        style="height: 35px"
                    >
                      <v-toolbar-title style="height: 35px">
                        {{ n.name }}
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <ul v-for="u in n.details" :key="u.id">
                        <li>
                          <v-checkbox
                              :id="parseInt(u.id)"
                              v-model="create.selectedDetail"
                              :label="`${u.name}`"
                              :value="u"
                              dense
                              hide-details
                          ></v-checkbox>
                        </li>
                      </ul>
                      <!-- <v-text-field
                      outlined
                      dense
                      label="comment"
                      :hint="n.description"
                      persistent-hint
                    ></v-text-field> -->
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!-- <p>{{ create.selectedDetail }}</p> -->

            </v-card-text>
          </v-card>
        </div>
      </v-tab-item>
      <v-tab-item key="notes" style="padding-top: 20px">
        <v-card v-resize="onResize" flat>
          <div :style="{ height: panheight }" class="scrollable">
            <v-card-text>
              <vue-editor v-model="create.my_notes" placeholder="Notes *"></vue-editor>
            </v-card-text>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item key="summary_history" style="padding-top: 20px">
        <v-card v-resize="onResize" flat>
          <div :style="{ height: panheight }" class="scrollable">
            <v-card-text>
              <vue-editor v-model="create.summary_history" placeholder="Summary History *"></vue-editor>
            </v-card-text>
          </div>
        </v-card>
      </v-tab-item>
      <div class="required">Required: *</div>
    </v-tabs-items>

    <v-card flat style="margin-top: 4px">
      <v-card-actions>
        <v-btn
            :disabled="$v.create.$invalid"
            color="primary"
            text
            @click="submit"
        >
          save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {VueEditor} from "vue2-editor";
import Restful from "@/services/RestFul";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import Utils from "@/mixins/utils";
import Measurements from "../../../../mixins/measurements";

export default {
  props: {id: {type: String}},
  components: {
    VueEditor,
  },
  mixins: [Utils, Measurements, validationMixin],
  data: () => ({
    overlay: false,
    myHistory: null,
    dataList: [],
    create: {
      myId: null,
      main_problem: null,
      drugs: false,
      drug_details: null,
      historic_complaint: null,
      selectedDetail: [],
      my_notes: null,
      summary_history: null,
    },
  }),
  validations: {
    create: {
      main_problem: {
        required,
      },
      drugs: {
        required,
      },
      my_notes: {
        required,
      },
      summary_history: {
        required,
      },
    },
  },
  computed: {
    panheight: function () {
      let h = this.windowSize.y - 425 + "px";
      return h;
    },
  },
  created() {
    this.list();
  },
  methods: {
    list() {
      this.overlay = true;
      this.loading = true;
      console.log(this._decode(this.id));
      Restful.general.systemics
          .history()
          .then((response) => {
            console.log('dataList', response.data);
            if (response.status === 200) {
              this.dataList = response.data;
            } else {
              this.dataList = [];
            }
            this.loading = false;
            this.overlay = false;
          })
          .catch((error) => {
            console.log(error.response);
          });
    },
    submit() {
      let medics_available_timesid = parseInt(this._decode(this.id));
      let main_problem = this.create.main_problem;
      let drugs = this.create.drugs;
      let drug_details = this.create.drug_details;
      let historic_complaint = this.create.historic_complaint;
      let jsonData = this.create.selectedDetail;
      let medic_notes = this.create.my_notes;
      let summary_history = this.create.summary_history;

      let data = {
        medics_available_timesid,
        main_problem,
        drugs,
        drug_details,
        historic_complaint,
        jsonData,
        medic_notes,
        summary_history,
      };
      console.log(data);
      let payload = {data: data};
      this.$emit("submitNew", payload);
    },
  },
};
</script>

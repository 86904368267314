<template>
  <div>
    <v-overlay :value="overlay" absolute dark></v-overlay>
    <v-card flat>
      <v-card-text>
        <v-tabs v-model="myLabs">
          <v-tab key="exam">examination findings</v-tab>
          <v-tab key="summary">summary of exam findings</v-tab>
        </v-tabs>
        <v-tabs-items v-model="myLabs">
          <v-tab-item key="exam" style="padding-top: 20px">
            <v-card v-resize="onResize" flat>
              <div :style="{ height: panheight }" class="scrollable">
                <v-card-text>
                  <vue-editor
                      v-model="create.exam_findings"
                      placeholder="Examination Findings *"
                  ></vue-editor>
                </v-card-text>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item key="summary" style="padding-top: 20px">
            <v-card v-resize="onResize" flat>
              <div :style="{ height: panheight }" class="scrollable">
                <v-card-text>
                  <vue-editor
                      v-model="create.summary"
                      placeholder="Summary of exam findings"
                  ></vue-editor>
                </v-card-text>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <div class="required">Required: *</div>
      </v-card-text>
      <v-card-actions>
        <v-btn
            :disabled="$v.create.$invalid"
            color="primary"
            text
            @click="submit"
        >
          submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import Utils from "@/mixins/utils";
import Measurements from "../../../../mixins/measurements";
import {VueEditor} from "vue2-editor";

export default {
  props: {id: {type: String}},
  mixins: [Utils, Measurements, validationMixin],
  components: {VueEditor},
  data: () => ({
    myLabs: null,
    menu2: null,
    overlay: false,
    create: {
      exam_findings: null,
      summary: null,
    },
  }),
  validations: {
    create: {exam_findings: {required}},
  },
  computed: {
    panheight: function () {
      let h = this.windowSize.y - 425 + "px";
      return h;
    },
  },
  created() {
  },
  methods: {
    submit() {
      let medics_available_timesid = parseInt(this._decode(this.id));
      let exam_findings = this.create.exam_findings;
      let summary = this.create.summary;

      let data = {
        medics_available_timesid,
        exam_findings,
        summary,
      };
      let payload = {data: data};
      console.log("payload: ", payload);
      console.log("payload: ", JSON.stringify(payload));
      this.$emit("submitNew", payload);
    },
  },
};
</script>

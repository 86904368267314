<template>
  <div style="padding: 0 0px 16px; margin-top: 30px">
    <v-card v-resize="onResize" flat>
      <div :style="{ height: panheight }" class="scrollable">
        <v-card-text style="padding: 10px 16px 0">
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-row dense no-gutters>
                <v-col cols="12" sm="8">
                  <v-text-field
                      v-model="create.diag_temperature"
                      dense
                      label="Temperature *"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                      v-model="create.diag_temp_measure_id"
                      :items="myTemperaturelist"
                      dense
                      item-text="shortcut"
                      item-value="id"
                      label="type"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row dense no-gutters>
                <v-col cols="12" sm="4">
                  <v-text-field
                      v-model="create.diag_bp_systolic"
                      dense
                      label="BP Systolic *"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                      v-model="create.diag_bp_diastolic"
                      dense
                      label="BP Diastolic *"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                      v-model="create.diag_bp_measure_id"
                      :items="myPressurelist"
                      dense
                      item-text="shortcut"
                      item-value="id"
                      label="Type"
                  ></v-select>
                </v-col>
              </v-row>
              <!---->
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="12" sm="8">
                  <v-text-field
                      v-model="create.diag_pulse"
                      dense
                      label="Pulse *"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                      v-model="create.diag_pulse_measure_id"
                      :items="myCounting"
                      dense
                      item-text="shortcut"
                      item-value="id"
                      label=" type"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="12" sm="8">
                  <v-text-field
                      v-model="create.diag_weight"
                      dense
                      label="Weight *"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                      v-model="create.diag_weight_measure_id"
                      :items="myMasslist"
                      dense
                      item-text="name"
                      item-value="id"
                      label="weights"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="12" sm="8">
                  <v-text-field
                      v-model="create.diag_height"
                      dense
                      label="Height"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                      v-model="create.diag_height_measure_id"
                      :items="mylengthlist"
                      dense
                      item-text="shortcut"
                      item-value="id"
                      label="lengths"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="12" sm="8">
                  <v-text-field
                      v-model="create.diag_respiratory_rate"
                      dense
                      label="Respiratory Rate"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                      v-model="create.diag_respiratory_measure_id"
                      :items="myCounting"
                      dense
                      item-text="shortcut"
                      item-value="id"
                      label="rate"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="12" sm="8">
                  <v-text-field
                      v-model="create.diag_steps_count"
                      dense
                      label="Steps Count"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                      v-model="create.diag_steps_measure_id"
                      :items="myCounting"
                      dense
                      item-text="shortcut"
                      item-value="id"
                      label="counts"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="12" sm="8">
                  <v-text-field
                      v-model="create.diag_oxy_saturation"
                      dense
                      label="Oxy Saturation"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                      v-model="create.diag_oxy_measure_id"
                      :items="myPressurelist"
                      dense
                      item-text="shortcut"
                      item-value="id"
                      label="densities"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                  v-model="create.diag_visual_acuity_left"
                  dense
                  label="Visual Acuity Left"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                  v-model="create.diag_visual_acuity_right"
                  dense
                  label="Visual Acuity Right"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="12" sm="8">
                  <v-text-field
                      v-model="create.diag_occipital_circum"
                      dense
                      label="Occipital Circum"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                      v-model="create.diag_occipital_measure_id"
                      :items="myCounting"
                      dense
                      item-text="shortcut"
                      item-value="id"
                      label="type"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="12" sm="8">
                  <v-text-field
                      v-model="create.diag_mid_upper"
                      dense
                      label="Mid Upper (MUAC)"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                      v-model="create.diag_muac_measure_id"
                      :items="myCounting"
                      dense
                      item-text="shortcut"
                      item-value="id"
                      label="type"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-switch
                  v-model="create.diag_with_glasses"
                  :label="`Wear Glasses: ${create.diag_with_glasses}`"
                  style="margin-top: 1px; padding-left: 4px"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="12" sm="8">
                  <v-text-field
                      v-model="create.diag_intra_occula_pressure"
                      dense
                      label="Intra Occula Pressure"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                      v-model="create.diag_intr_occualar_measure_id"
                      :items="myPressurelist"
                      dense
                      item-text="shortcut"
                      item-value="id"
                      label="type"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-textarea
              v-model="create.diag_other"
              label="Other"
              outlined
              rows="3"
          ></v-textarea>
          <!-- grwgrg<br />
          grwgrg<br />
          grwgrg<br /> -->
          <div class="required">Required: *</div>
        </v-card-text>
      </div>
    </v-card>
    <v-card-actions>
      <v-btn
          :disabled="$v.create.$invalid"
          color="primary"
          text
          @click="submit"
      >
        save
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import Utils from "@/mixins/utils";
import Measurements from "../../../../mixins/measurements";

export default {
  props: {id: {type: String}},
  mixins: [Utils, Measurements, validationMixin],
  data: () => ({
    create: {
      diag_pulse: 0,
      diag_pulse_measure_id: 12,
      diag_oxy_saturation: 0,
      diag_oxy_measure_id: 10,
      diag_steps_count: 0,
      diag_steps_measure_id: 12,
      diag_temperature: 0,
      diag_temp_measure_id: 8,
      diag_bp_systolic: 0,
      diag_bp_diastolic: 0,
      diag_bp_measure_id: 10,
      diag_respiratory_rate: 0,
      diag_respiratory_measure_id: 12,
      diag_weight: 0,
      diag_weight_measure_id: 7,
      diag_height: 0,
      diag_height_measure_id: 1,
      diag_occipital_circum: 0,
      diag_occipital_measure_id: 12,
      diag_mid_upper: 0,
      diag_muac_measure_id: 12,
      diag_visual_acuity_left: 0,
      diag_visual_acuity_right: 0,
      diag_with_glasses: false,
      diag_intra_occula_pressure: 0,
      diag_intr_occualar_measure_id: 10,
      diag_other: 0,
    },
  }),
  validations: {
    create: {
      // diag_temperature: {
      //   required,
      //   decimal,
      //   minValue: minValue(1),
      // },
      // diag_temp_measure_id: {
      //   required,
      //   decimal,
      //   minValue: minValue(1),
      // },
      // diag_bp_systolic: {
      //   required,
      //   decimal,
      //   minValue: minValue(1),
      // },
      // diag_bp_diastolic: {required, decimal, minValue: minValue(1)},
      // diag_bp_measure_id: {required, minValue: minValue(1)},
      // diag_pulse: {required, decimal, minValue: minValue(1)},
      // diag_pulse_measure_id: {required, minValue: minValue(1)},
      // diag_weight: {required, decimal, minValue: minValue(1)},
      // diag_weight_measure_id: {required, minValue: minValue(1)},
    },
  },
  computed: {
    panheight: function () {
      let h = this.windowSize.y - 380 + "px";
      return h;
    },
  },
  methods: {
    submit() {
      let medics_available_timesid = parseInt(this._decode(this.id));
      let diag_pulse = this.create.diag_pulse;
      let diag_pulse_measure_id = this.create.diag_pulse_measure_id;
      let diag_oxy_saturation = this.create.diag_oxy_saturation;
      let diag_oxy_measure_id = this.create.diag_oxy_measure_id;
      let diag_steps_count = this.create.diag_steps_count;
      let diag_steps_measure_id = this.create.diag_steps_measure_id;
      let diag_temperature = this.create.diag_temperature;
      let diag_temp_measure_id = this.create.diag_temp_measure_id;
      let diag_bp_systolic = this.create.diag_bp_systolic;
      let diag_bp_diastolic = this.create.diag_bp_diastolic;
      let diag_bp_measure_id = this.create.diag_bp_measure_id;
      let diag_respiratory_rate = this.create.diag_respiratory_rate;
      let diag_respiratory_measure_id = this.create.diag_respiratory_measure_id;
      let diag_weight = this.create.diag_weight;
      let diag_weight_measure_id = this.create.diag_weight_measure_id;
      let diag_height = this.create.diag_height;
      let diag_height_measure_id = this.create.diag_height_measure_id;
      let diag_occipital_circum = this.create.diag_occipital_circum;
      let diag_occipital_measure_id = this.create.diag_occipital_measure_id;
      let diag_mid_upper = this.create.diag_mid_upper;
      let diag_muac_measure_id = this.create.diag_muac_measure_id;
      let diag_visual_acuity_left = this.create.diag_visual_acuity_left;
      let diag_visual_acuity_right = this.create.diag_visual_acuity_right;
      let diag_with_glasses = this.create.diag_with_glasses;
      let diag_intra_occula_pressure = this.create.diag_intra_occula_pressure;
      let diag_intr_occualar_measure_id =
          this.create.diag_intr_occualar_measure_id;
      let diag_other = this.create.diag_other;
      let data = {
        medics_available_timesid,
        diag_pulse,
        diag_pulse_measure_id,
        diag_oxy_saturation,
        diag_oxy_measure_id,
        diag_steps_count,
        diag_steps_measure_id,
        diag_temperature,
        diag_temp_measure_id,
        diag_bp_systolic,
        diag_bp_diastolic,
        diag_bp_measure_id,
        diag_respiratory_rate,
        diag_respiratory_measure_id,
        diag_weight,
        diag_weight_measure_id,
        diag_height,
        diag_height_measure_id,
        diag_occipital_circum,
        diag_occipital_measure_id,
        diag_mid_upper,
        diag_muac_measure_id,
        diag_visual_acuity_left,
        diag_visual_acuity_right,
        diag_with_glasses,
        diag_intra_occula_pressure,
        diag_intr_occualar_measure_id,
        diag_other,
      };
      console.log(data);
      let payload = {data: data};
      this.$emit("submitNew", payload);
    },
  },
};
</script>

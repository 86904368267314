<template>
  <div style="padding: 0 0px 16px">
    <v-overlay :value="overlay" absolute dark></v-overlay>
    <v-card flat>
      <v-card-text>
        <v-tabs v-model="myLabs">
          <v-tab key="general">diagnostics request</v-tab>
          <v-tab key="imaging">imaging request</v-tab>
          <v-tab key="other">other request</v-tab>
        </v-tabs>
        <v-tabs-items v-model="myLabs">
          <v-tab-item key="general" style="padding-top: 20px">
            <v-card v-resize="onResize" flat>
              <div :style="{ height: panheight }" class="scrollable">
                <v-card-text style="padding: 0 6px">
                  <v-card color="#f1f7ff" outlined>
                    <v-card-text style="padding: 20px 16px 0">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-row>
                            <v-col cols="12" sm="6">
                              <v-switch
                                  v-model="create.fasting"
                                  :label="`Fasting Sample: ${
                                  create.fasting ? 'Yes' : 'No'
                                }`"
                              ></v-switch>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-switch
                                  v-model="create.urgency"
                                  :label="`Urgency: ${
                                  create.urgency ? 'Yes' : 'No'
                                }`"
                              ></v-switch>
                            </v-col>
                          </v-row>

                          <v-menu
                              v-model="menu2"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              min-width="auto"
                              offset-y
                              transition="scale-transition"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  v-model="create.last_dose_date_time"
                                  dense
                                  label="last dose taken"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="create.last_dose_date_time"
                                :max="today"
                                @input="menu2 = false"
                            ></v-date-picker>
                          </v-menu>
                          <v-text-field
                              v-model="create.drug_therapy"
                              dense
                              label="drug therapy *"
                          ></v-text-field>
                          <v-text-field
                              v-model="create.clinical_info"
                              dense
                              label="clinical information"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-textarea
                              v-model="create.profile_test"
                              counter
                              label="other instructions"
                              outlined
                              rows="7"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <br/>

                  <v-card
                      class="d-flex justify-space-between align-content-start flex-wrap"
                      flat
                      tile
                  >
                    <v-card
                        v-for="item in dataList"
                        :key="item.id"
                        class="pa-2 dyna_form_data"
                        outlined
                        style="margin-bottom: 10px; width: 100%"
                    >
                      <v-toolbar color="rgb(245, 245, 245)" dense flat>
                        <v-toolbar-title>
                          {{ item.name }}
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-card-text style="padding: 20px 7px 10px">
                        <v-row>
                          <v-col
                              v-for="d in item.details"
                              :key="d.id"
                              class="pa-2"
                              outlined
                              tile
                          >
                            <v-card
                                outlined
                                style="
                                min-width: 180px;
                                padding-bottom: 10px;
                                margin-bottom: 16px;
                              "
                            >
                              <v-card-title>{{ d.name }}</v-card-title>
                              <v-card-text>
                                <ul v-for="u in d.units" :key="u.id">
                                  <li>
                                    <v-checkbox
                                        v-model="create.selectedDetail"
                                        :label="`${u.name}`"
                                        :value="u"
                                        dense
                                        hide-details
                                    ></v-checkbox>
                                  </li>
                                </ul>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-card>
                </v-card-text>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item key="imaging" style="padding-top: 20px">
            <v-card v-resize="onResize" flat style="margin-bottom: 20px">
              <div :style="{ height: panheight }" class="scrollable">
                <v-card-text>
                  <vue-editor
                      v-model="create.imaging"
                      placeholder="Example below:
            1. X-ray
                a.  cervical spine
                b.  Antero-Posterior
                c.  Lateral
            2."
                  ></vue-editor>
                </v-card-text>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item key="other" style="padding-top: 20px">
            <v-card v-resize="onResize" flat style="margin-bottom: 20px">
              <div :style="{ height: panheight }" class="scrollable">
                <v-card-text>
                  <vue-editor
                      v-model="create.other_specimens"
                      placeholder="Example below:
            1. MicroBiology
                a. Skin
                    i. Skin Scrapping: You can optionally add any comment to this speciment item before moving to the next
            2."
                  ></vue-editor>
                </v-card-text>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <div class="required">Required: *</div>

      </v-card-text>
      <v-card-actions>
        <v-btn
            :disabled="$v.create.$invalid"
            color="primary"
            text
            @click="submit"
        >
          save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import Utils from "@/mixins/utils";
import Measurements from "../../../../mixins/measurements";
import {VueEditor} from "vue2-editor";

export default {
  props: {id: {type: String}},
  mixins: [Utils, Measurements, validationMixin],
  components: {
    VueEditor,
  },
  data: () => ({
    myLabs: null,
    menu2: null,
    overlay: false,
    dataList: [],
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    create: {
      drug_therapy: null,
      fasting: false,
      urgency: false,
      last_dose_date_time: null,
      imaging: null,
      clinical_info: null,
      profile_test: null,
      other_specimens: null,
      selectedDetail: [],
    },
  }),
  validations: {
    create: {drug_therapy: {required}},
  },
  computed: {
    panheight: function () {
      let h = this.windowSize.y - 425 + "px";
      return h;
    },
  },
  created() {
    this.list();
  },
  methods: {
    list() {
      this.overlay = true;
      this.loading = true;
      console.log(this._decode(this.id));
      Restful.general.labs
          .list()
          .then((response) => {
            console.log(response.data);
            if (response.status === 200) {
              this.dataList = response.data;
            } else {
              this.dataList = [];
            }
            this.loading = false;
            this.overlay = false;
          })
          .catch((error) => {
            console.log(error.response);
          });
    },
    submit() {
      let medics_available_timesid = parseInt(this._decode(this.id));
      let drug_therapy = this.create.drug_therapy;
      let fasting = this.create.fasting;
      let urgency = this.create.urgency;
      let last_dose_date_time = Date.parse(this.create.last_dose_date_time);
      let clinical_info = this.create.clinical_info;
      let profile_test = this.create.profile_test;
      let other_specimens = this.create.other_specimens;
      let imaging = this.create.imaging;
      let jsonData = this.create.selectedDetail;
      let data = {
        medics_available_timesid,
        drug_therapy,
        fasting,
        urgency,
        last_dose_date_time,
        clinical_info,
        profile_test,
        other_specimens,
        imaging,
        jsonData,
      };
      let payload = {data: data};
      console.log("payload: ", payload);
      console.log("payload: ", JSON.stringify(payload));
      this.$emit("submitNew", payload);
    },
  },
};
</script>
